<template>
  <div>
    <ListHeader entity-name="video" router-name="VideoNew"></ListHeader>
    <Modal model="video" @confirm-deletion="deleteRecord"/>
	  <div class="default-according style-1 faq-accordion default-accordion" id="accordionoc">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-3 xl-40">
            <FilterCard :search-fields="searchFields"
                        :submit-button="searchButton"
                        @searchRecords="search">
            </FilterCard>
          </div>
          <div class="col-xl-9 xl-60">
            <SharedCard :records="records"
                        model-name="Video"
                        :pages="pages"
                        :paginate="paginate"
                        :send-record="sendRecord"
                        :publish="publish"
                        :unpublish="unpublish"
                        :options="options">
            </SharedCard>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import ListsMixin from 'mixins/ListsMixin';
import ContentListsMixin from 'mixins/ContentListsMixin';

export default {
  name:'VideoList',
  data() {
    return {
      modelName: 'video'
 	  };
  },
  computed: {
    ...mapGetters({
      records: 'videos',
      tagsList: 'tagsList',
      currentUser: 'currentUser'
    })
  },
  methods: {
    ...mapActions({
      getRecords: 'getVideos',
      destroyRecord: 'destroyVideo',
      publishRecord: 'publishVideo',
      unpublishRecord: 'unpublishVideo',
      searchTags: 'searchTags'
    })
  },
  mixins: [ListsMixin, ContentListsMixin]
};
</script>
